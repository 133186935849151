import React from "react";

const UserPage = ({ data }) => {
  return (
    <>
      <p>Users page not needed</p>
    </>
  );
};

export default UserPage;